<script>
  import ExportResults from '@/components/ExportResults.vue'
  import ProductDetails from '@/components/product-details.vue'
  import ProductImage from '@/components/product-image.vue'
  import ProductItem from '@/components/product-item.vue'
  export default {
    name: 'product-results',
    props: {
      pageMeta: {
        type: Object,
        required: true,
      },
      products: {
        type: Array,
        required: true,
      },
    },
    components: { ExportResults, ProductDetails, ProductImage, ProductItem },
    data: () => ({
      filters: {
        isMarkdown: false,
        search: null,
        sort: 'nameasc',
      },
      defaultValues: {productId: '', productName: '', price: 0},
      productSelected: null,
      markdownCheckbox: false,
      modalDetailShow: false,
      modalImageShow: false,
      modalExportShow: false,
      showFilters: true,
      sortOptions: [
        { title: 'ID Asc', key: 'productId', direction: 1, value: 'idasc' },
        { title: 'ID Desc', key: 'productId', direction: -1, value: 'iddesc' },
        { title: 'Name Asc', key: 'productName', direction: 1, value: 'nameasc' },
        { title: 'Name Desc', key: 'productName', direction: -1, value: 'namedesc' },
        { title: 'Price Asc', key: 'price', direction: 1, value: 'priceasc' },
        { title: 'Price Desc', key: 'price', direction: -1, value: 'pricedesc' },
      ],
    }),
    computed: {
      productsDisplayed() {
        let products = this.products.map(p => ({...this.defaultValues, ...p}))
        if (this.filters.search) {
          // p.categoryTree.toLowerCase().includes(this.filters.search.toLowerCase()) ||
          products = products.filter(
            p =>
              p.productId.toLowerCase().includes(this.filters.search.toLowerCase()) ||
              p.productName.toLowerCase().includes(this.filters.search.toLowerCase()) ||
              p.price.toString().includes(this.filters.search.toLowerCase()),
          )
        }

        if (this.filters.sort) {
          if (this.sortData) {
            products = products.sort((a, b) => {
              if (this.sortData.key === 'price') {
                return this.sortData.direction === 1 ? a?.[this.sortData.key] - b?.[this.sortData.key] : b?.[this.sortData.key] - a?.[this.sortData.key]
              } else {
                return this.sortData.direction === 1
                  ? a?.[this.sortData.key].toString().localeCompare(b?.[this.sortData.key])
                  : b?.[this.sortData.key].toString().localeCompare(a?.[this.sortData.key])
              }
            })
          }
        }
        products = products.map(p => {
          let message = ''
          let colors = []
          let colorsMarkdown = []
          const itemColors = p?.c_customData?.aggregatedDetails?.colors || []
          if (!itemColors?.length) {
            message = 'no colors found'
            //fallback to root item?
          } else {
            [...itemColors].forEach(item => {
              const priceText = item.price.toString() || ''
              const priceSplit = priceText.split('.')
              const cents = priceSplit?.[1]?.padEnd(2, '0') || '00'
              const priceDisplayed =  `$${priceSplit[0]}.${cents}`
              const imageUrl = `https://www.madewell.com/images/${item.masterId}_${item.id}${item.shotType[0]}?wid=1200&hei=1525&fmt=jpeg&fit=crop&qlt=75`
              if (cents == '99') {
                colorsMarkdown.push({...item, imageUrl, priceDisplayed})
              } else {
                colors.push({...item, priceDisplayed})
              }
            })
          }
          // const priceText = p.price.toString() || ''
          // const priceSplit = priceText.split('.')
          // const cents = priceSplit?.[1]?.padEnd(2, '0') || '00'
          delete p.isInfo
          return {
            ...p,
            colors, 
            colorsMarkdown,
            isMarkdown: colorsMarkdown?.length && !colors?.length,
            message,
            pdpUrl: p.pdpUrl.substr(0, 1) === '/' ? 'https://www.madewell.com/' + p.pdpUrl : p.pdpUrl,
          }
        })

        if (this.filters.isMarkdown) {
          products = products.filter(p => p.isMarkdown)
        }
        return products
      },
      resultsText() {
        return `Displaying ${this.productsDisplayed.length} / ${this.products.length} products`
      },
      showItemCountError() {
        return parseInt(this.products?.length) < parseInt(this.pageMeta?.resultsCount)
      },
      sortData() {
        return this.sortOptions.find(o => o.value === this.filters.sort)
      },
    },
    methods: {
      //not used?
      getDataUrl(img) {
        // Create canvas
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        // Set width and height
        canvas.width = img.width
        canvas.height = img.height
        // Draw the image
        ctx.drawImage(img, 0, 0)
        return canvas.toDataURL('image/jpeg')
      },
      //not used?
      hex(buffer) {
        var hexCodes = []
        var view = new DataView(buffer)
        for (var i = 0; i < view.byteLength; i += 4) {
          // Using getUint32 reduces the number of iterations needed (we process 4 bytes each time)
          var value = view.getUint32(i)
          // toString(16) will give the hex representation of the number without padding
          var stringValue = value.toString(16)
          // We use concatenation and slice for padding
          var padding = '00000000'
          var paddedValue = (padding + stringValue).slice(-padding.length)
          hexCodes.push(paddedValue)
        }

        // Join all the hex strings into one
        return hexCodes.join('')
      },

      showDetails(item) {
        this.productSelected = item
        this.modalDetailShow = true
      },

      showImage(item) {
        this.productSelected = item
        this.modalImageShow = true
      },
    },
    created() {
      // let dataUrl = ''
      // const imgUrl =
      //   'https://i.s-madewell.com/is/image/madewell/MD234_EE0202_ld?$MW%5F480x610$'
      // const img = new Image()
      // img.src = imgUrl
      // img.onload = (e) => {
      //   // dataUrl = this.getDataUrl(e.target)
      //   console.log(dataUrl)
      //   crypto.subtle.digest('SHA-256', e).then((hash) => {
      //     const sha256result = this.hex(hash)
      //     dataUrl = sha256result
      //     // this should contain your sha-256 hash value
      //     console.log(sha256result)
      //   })
      //   return dataUrl
      // }
    },
  }
</script>

<template>
  <v-sheet color="primary" width="100%" min-height="100vh" class="d-flex flex-column align-center pa-4">
    <v-card flat max-width="900px" tile width="100%" class="results-filter-card">
      <v-app-bar flat color="primary">
        <a :href="pageMeta.url" target="_blank" :title="pageMeta.url" class="secondary--text text-h6 font-weight-bold text-decoration-none">
          {{ pageMeta.pageTitle }}
        </a>
        <v-tooltip color="secondary" outlined top>
          <template v-slot:activator="{ on }">
            <v-btn color="secondary" icon small @click="$emit('edit', pageMeta)" class="ml-2" v-on="on"><v-icon small>mdi-pencil</v-icon></v-btn>
          </template>
          <span class="primary--text">Edit Search Label</span>
        </v-tooltip>
      </v-app-bar>
      <v-sheet color="transparent">
        <v-sheet color="transparent class"> </v-sheet>
      </v-sheet>
      <v-sheet color="primary" max-width="900px" tile width="100%" class="d-flex flex-column align-start justify-start px-4">
        <v-text-field v-model="filters.search" clearable full-width hide-details label="Search" name="filters.search" solo type="text" class="full-width"></v-text-field>
        <v-sheet color="primary" class="d-flex align-start justify-start text-caption secondary--text pt-1 font-weight-bold">{{ resultsText }}</v-sheet>
        <v-sheet color="transparent" class="d-flex align-center flex-wrap justify-space-between full-width pb-4">
          <v-menu transition="slide-x-transition" bottom right class="">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" text tile v-bind="attrs" v-on="on" class="sort-menu-btn my-4"> <v-icon left>mdi-sort</v-icon> {{ sortData.title }} </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="option in sortOptions" :key="option.value" @click="filters.sort = option.value">
                <v-list-item-title class="text-left primary--text font-weight-bold">{{ option.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-checkbox v-model="filters.isMarkdown" color="secondary" dark hide-details="" label="On markdown" class="my-4"></v-checkbox>
          <v-dialog v-model="modalExportShow" width="100%" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="secondary" text tile class="export-btn px-0 my-4"><v-icon>mdi-download</v-icon>EXPORT DISPLAYED</v-btn>
            </template>
            <export-results v-if="modalExportShow" :results="productsDisplayed" @close="modalExportShow = false"></export-results>
          </v-dialog>
        </v-sheet>
        <v-alert dense border="left" dismissible icon="mdi-alert" type="warning" :value="showItemCountError" class="full-width text-left">
          <p class="mb-0 text-body-1"><span class="font-weight-bold"> Warning: </span> Possible items missing.</p>
          <p class="mb-0 text-body-2">Page reported {{ pageMeta.resultsCount }} items but only received {{ products.length }}.</p>
        </v-alert>
      </v-sheet>
    </v-card>

    <v-card flat max-width="900px" tile width="100%" class="rounded">
      <v-card-text class="d-flex flex-column align-start">
        <v-list three-line width="100%" class="pb-10">
          <transition-group name="fade">
            <!-- <transition-group name="list"> -->
            <template v-for="(product, idx) in productsDisplayed">
              <product-item :key="product.productId + idx" :product="product" @showDetails="showDetails" @showImage="showImage"></product-item>
              <v-divider :key="product.productId + idx + 'dv'" inset class="primary"></v-divider>
            </template>
          </transition-group>
          <p v-if="productsDisplayed.length < 1 && products.length > 1" class="primary--text font-weight-bold text-center">No results found</p>
        </v-list>
        <v-dialog v-model="modalDetailShow" width="100%" max-width="600" scrollable>
          <Product-details v-if="modalDetailShow" :product="productSelected" @close="modalDetailShow = false"></Product-details>
        </v-dialog>
        <v-dialog v-model="modalImageShow" width="auto" max-width="600" overlay-opacity=".96">
          <Product-image v-if="modalImageShow" :img="productSelected.imageUrl" @close="modalImageShow = false"></Product-image>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>
<style lang="scss" scoped>
  @media (max-width: 850px) {
    .wave-container::before {
      height: 65px;
    }
  }

  @media (max-width: 850px) {
    .wave-container::before {
      height: 18.5px;
    }
  }

  .results-filter-card {
    position: sticky;
    top: 0px;
    z-index: 10;
  }

  .sort-menu-btn {
    // margin-top: 1rem;
    padding: 0px;
    border-bottom: solid white 1px;
  }

  .detail-subtitle {
    white-space: normal;
    word-wrap: normal;
  }

  .export-btn {
    height: 24px !important;
  }
  .export-btn::before {
    height: 24px;
  }
  .v-dialog {
    box-shadow: none;
  }

  /* moving */
  .fade-move {
    transition: all 600ms ease-in-out 500ms;
  }

  /* appearing */
  .fade-enter-active {
    transition: all 400ms ease-out;
  }

  /* disappearing */
  .fade-leave-active {
    transition: all 200ms ease-in;
    position: absolute;
    z-index: 0;
  }

  /* appear at / disappear to */
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
